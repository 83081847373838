import { memo } from "react"
import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useSelector } from "react-redux";
import { useLogEvent } from "../hooks/useLogEvent";
import { globalTexts } from "../constants/TextConstants";

const SocialContainer = styled.div<{ direction: 'row' | 'column' }>`
    display: flex;
    position: absolute;
    left: 9.5vw;
    z-index: 3;

    @media ${device.lg} {
        left: 4.5vw;
    }
    
    @media ${device.md} {
        left: 3.5vw;
    }

    ${({ direction }) => direction === 'row' ? css`
        flex-direction: row;
        left: unset;
        position: relative;
    ` : css`
        flex-direction: column;
    `}

`

const SocialButton = styled.button`
    width: 45px;
    height: 45px;
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    box-shadow: 0px 1px 6px 0px rgba(255, 255, 255, 0.25);
    cursor: pointer;
    transition: all .3s ease;
    margin: 7.5px;

    &:hover {
        background-color: #e1e1e1;
        transform: scale(1.1);
    }
    
    @media ${device.sm} {
        width: 35px;
        height: 35px;
    }

    @media ${device.xs} {
        width: 30px;
        height: 30px;
    }
`
const SocialImage = styled.div<{ img: string }>`
    width: 20px;
    height: 20px;
    object-fit: contain;
    transition: all .3s ease;

    @media ${device.sm} {
        width: 16px;
        height: 16px;
    }

    @media ${device.xs} {
        width: 14px;
        height: 14px;
    }

    ${({ img }) => img && css`
        background: url(${img}) center no-repeat;
        background-size: contain;
    `}
`

interface Props {
    direction: 'row' | 'column';
    inProgress?: boolean;
}

const SocialMenuComponent = ({ direction, inProgress }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const { handleLogEvent } = useLogEvent();

    return (

        <>

            {
                !inProgress && (

                    <SocialContainer direction={direction}>

                        <SocialButton
                            className={!loading ? `${direction === 'row' ? "utils__fade-in-bottom" : 'utils__fade-in-right'}` : ''}
                            onClick={(e) => {

                                window.open(globalTexts.instagramLink, "_blank");

                                handleLogEvent('click_boton_instagram')
                            }}
                        >

                            <SocialImage
                                className={!loading ? 'utils__fade-in-center' : ''}
                                // img={require(`../images/introduction/ig.png`)}
                                img={`${process.env.REACT_APP_CDN}/ig.png`}
                            />

                        </SocialButton>

                        <SocialButton
                            className={!loading ? `${direction === 'row' ? "utils__fade-in-bottom" : 'utils__fade-in-right'}` : ''}
                            onClick={(e) => {

                                window.open(globalTexts.tiktokLink, "_blank");

                                handleLogEvent('click_boton_tiktok')
                            }}
                        >

                            <SocialImage
                                className={!loading ? 'utils__fade-in-center' : ''}
                                // img={require(`../images/introduction/tiktok.png`)}
                                img={`${process.env.REACT_APP_CDN}/tiktok.png`}
                            />

                        </SocialButton>

                        {/* <SocialButton
                            className={!loading ? `${direction === 'row' ? "utils__fade-in-bottom" : 'utils__fade-in-right'}` : ''}
                            onClick={(e) => {

                                window.open(globalTexts.twitterLink, "_blank");

                                handleLogEvent('click_boton_twitter')
                            }}
                        >

                            <SocialImage
                                className={!loading ? 'utils__fade-in-center' : ''}
                                // img={require(`../images/introduction/twitter.png`)}
                                img={`${process.env.REACT_APP_CDN}/twitter.png`}
                            />

                        </SocialButton> */}


                    </SocialContainer>

                )
            }

        </>

    )
}

const SocialMenu = memo(SocialMenuComponent);

export default SocialMenu;