import Introduction from "../components/Introduction"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import Building from "../components/Building";
import { getFullDate } from "../utils/Utilities";
import TicketSection from "../components/TicketSection";
import DanceZone from "../components/DanceZone";
import About from "../components/About";
import Rights from "../components/Rights";
import SocialSection from "../components/SocialSection";
import { useSelector } from "react-redux";
import WsFloatButton from "../components/WsFloatButton";
import ActionMenu from "../components/ActionMenu";

export const HomeScreen = () => {

    const [showPreloader, setShowPreloader] = useState(true);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);

    const location = useLocation()

    const { loading } = useSelector((state: any) => state.ui);

    // const { pathname } = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);

    const preloader = () => {

        if (!loading) {

            setTimeout(() => {
                setShowPreloader(false);
            }, 3900);

        }

    };

    useEffect(() => {

        preloader()

        allAnimations();

        return () => { allAnimations() };


    }, [location.pathname, loading])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress preloader={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <ScreenContainer>

            {showPreloader && <Building showInProgress={false} preloader={true} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <WsFloatButton />

            <Introduction />

            <TicketSection />

            <ActionMenu />

            <DanceZone />

            <About />

            <SocialSection />

            <Rights brand="Fiebre Fiesta" backgroundColor="rgba(0, 0, 0, 0.25)" />

        </ScreenContainer>

    )
}
