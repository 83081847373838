import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts } from "../constants/TextConstants"

const Container = styled.div`
    max-width: 1600px;
    width: 90%;
    padding: 5vh 5% 15vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    z-index: 1;
    
    @media ${device.lg} {
        padding: 5vh 5% 10vh;
    }

`

const Title = styled.h2`
    color: white;
    font-size: 52px;
    letter-spacing: 7px;
    margin-bottom: 50px;
    width: 100%;
    text-transform: uppercase;

    @media ${device.lg} {
        width: 95%;
        font-size: 32px;
        letter-spacing: 7px;
        margin-bottom: 30px;
    }

    @media ${device.md} {
        font-size: 28px;
        letter-spacing: 7px;
        margin-bottom: 30px;
    }

    @media ${device.sm} {
        width: 90%;
        font-size: 22px;
        letter-spacing: 5px;
        margin-bottom: 30px;
    }

    @media ${device.xs} {
        font-size: 18px;
        letter-spacing: 3px;
        margin-bottom: 20px;
    }
`

const LocationMap = styled.iframe`
    width: 100%;
    height: 40vh;
    border: 1px solid #E2E2E2;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
`

interface Props {
    link: string;
}

export const Location = ({ link }: Props) => {

    return (

        <Container>

            <Title className="utils__fade-in-fast-right">Ubicación</Title>

            <LocationMap
                className="utils__fade-in-fast-top"
                src={link}
                loading="lazy"
            />

        </Container>

    )
}

export default Location;