import { memo } from "react"
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import SocialMenu from "./SocialMenu";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { startLoading } from "../actions/ui";

const Container = styled.div`
    padding: 5vh 10%;
    width: 80%;
    max-width: 1500px;
    align-self: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: relative;

    @media ${device.lg} {
        max-width: 800px;
    }
`

const Logo = styled.img`
    cursor: pointer;
    width: 180px;
    height: auto;
    
    @media ${device.md} {
        width: 150px;
    }

    @media ${device.sm} {
        width: 100px;
    }

    @media ${device.xs} {
        width: 80px;
    }
`

const HeaderTicketComponent = () => {

    const { loading } = useSelector((state: any) => state.ui);

    const dispatch: any = useDispatch();

    const history: any = useHistory();

    return (

        <Container id='home'>

            <Logo
                className={!loading ? 'utils__fade-in-bottom' : ''}
                // src={require(`../images/general/fiebre-old-school.png`)}
                src={`${process.env.REACT_APP_CDN}/fiebre-old-school.webp`}
                alt="Logo"
                onClick={async () => {

                    dispatch(startLoading());

                    await history.push('/');

                }}
            />

            <SocialMenu direction="row" />

        </Container>
    )
}

const HeaderTicket = memo(HeaderTicketComponent);

export default HeaderTicket;