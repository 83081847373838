import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useDispatch, useSelector } from "react-redux"
import { getFullDate, } from "../utils/Utilities"
import { ChangeEvent, useState } from "react"
import { showMixinToast } from "../utils/Alerts"
import Rights from "./Rights"
import Logos from "./Logos"
import { finishLoading } from "../actions/ui"

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #111111;

    @keyframes hide {
        0% {
            opacity: 1;
        }
        25% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    &.active {
        animation: hide 1s ease 3.5s;
    }
`

const ContentContainer = styled.div`
    width: 100%;
    height: 100%;
    padding-bottom: 15vh;
    margin-top: 10vh;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

`

const TitleContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    h1 {
        font-size: 28px;
        text-transform: uppercase;
        color: #FFF;
        letter-spacing: 10px;
        text-align: center;

        @media ${device.md} {
            font-size: 22px;
        }

        @media ${device.sm} {
            font-size: 18px;
        }

        @media ${device.xs} {
            font-size: 16px;
        }

    }

`

const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* @media ${device.lg} {
        gap: 30px;
    }

    @media ${device.md} {
        grid-template-columns: repeat(2, 1fr);
        gap: 30px;
    }

    @media ${device.sm} {
        grid-template-columns: repeat(1, 1fr);
        gap: 0px;
        row-gap: 30px;
    } */

`

const RightsDiv = styled.div`
    width: 100%;
    position: absolute;
    bottom: 0px;
`

const Input = styled.input`
    width: 70%;
    height: 45px;
    max-width: 500px;
    margin-top: 10px;
    border-radius: 5px;
    border: 2px solid white;
    background-color: black;
    color: white;
    padding: 0px 15px;
    font-size: 14px;
    z-index: 3;
`

const Button = styled.button`
    max-width: 500px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: white;
    color: black;
    padding: 5px 15px;
    font-size: 14px;
    z-index: 3;
`

interface Props {
    showInProgress: boolean;
    preloader: boolean;
    passwordOk: boolean;
    setPasswordOk: React.Dispatch<React.SetStateAction<boolean>>
}

export const Building = ({ showInProgress, preloader, passwordOk, setPasswordOk }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const dispatch = useDispatch();

    const [form, setForm] = useState('');

    const handleKeyPress = (event: any) => {
        if (event.key === 'Enter') {
            handleConfirm()
        }
    };

    const handleConfirm = () => {

        if (passwordOk === false && form.trim() !== '' && form.trim() === process.env.REACT_APP_IN_PROGRESS_PASSWORD) {

            showMixinToast('Acceso permitido', 'success', 'bottom');

            setPasswordOk(true);

            localStorage.setItem('passwordExpire', (parseInt(getFullDate()) + 10000000).toString().padEnd(17, '0'));

            dispatch(finishLoading());

        } else {
            showMixinToast('contraseña incorrecta', 'error', 'bottom');
        }

    }

    return (

        <Container className={!loading ? 'active' : ''}>

            <ContentContainer>

                {
                    showInProgress && (

                        <TitleContainer>

                            <h1>Pagina en proceso</h1>

                        </TitleContainer>
                    )
                }


                <InfoContainer>

                    <Logos inProgress preloader={preloader} />

                </InfoContainer>

                {
                    showInProgress && (

                        <>

                            <Input
                                type="text"
                                placeholder="Ingrese la clave..."
                                value={form}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => setForm(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                            <Button onClick={() => handleConfirm()}>Ingresar</Button>

                        </>

                    )
                }
            </ContentContainer>

            {
                showInProgress && (

                    <RightsDiv>
                        <Rights brand="Fiebre Fiesta" />
                    </RightsDiv>

                )
            }

        </Container >

    )
}

export default Building;