
export const getFullDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());
    const hours = formatTwoNumbers(date.getHours());
    const minutes = formatTwoNumbers(date.getMinutes());
    const seconds = formatTwoNumbers(date.getSeconds());
    const miliseconds = fillWithCeros(date.getMilliseconds().toString(), 3);

    fullDate = `${year}${month}${day}${hours}${minutes}${seconds}${miliseconds}`;

    return fullDate;

}

const formatTwoNumbers = (month) => {

    let m = month;

    if (m < 10) {

        m = `0${m}`;

    }

    return m;

}

export const fillWithCeros = (inputString, length) => {
    // Verificar la longitud actual del string
    if (inputString.length < length) {
        // Calcular la cantidad de ceros que se deben agregar
        const cerosLeft = length - inputString.length;

        // Completar con ceros a la izquierda
        const string = '0'.repeat(cerosLeft) + inputString;

        return string;
    } else {
        // Si ya tiene 12 caracteres o más, devolver el string original
        return inputString;
    }
}

export const formatPrice = (price) => {

    let p = price;
    const internationarFormat = new Intl.NumberFormat('de-DE', { maximumFractionDigits: 2 })

    p = internationarFormat.format(p);

    return p;

} 

export const getDate = () => {

    const date = new Date();

    let fullDate = '';
    const year = date.getFullYear();
    const month = formatTwoNumbers(date.getMonth() + 1);
    const day = formatTwoNumbers(date.getDate());

    fullDate = `${year}${month}${day}`;

    return fullDate;

}

export const getTicketsList = (list) => {

    let newList = list.filter((value) => { return value.showUntil >= parseInt(getDate()) });

    return newList
}

export const getCurrentUTCTime = () => {
    const now = new Date();
    return now.toUTCString();
};