import Swal, { SweetAlertIcon, SweetAlertPosition } from "sweetalert2"

export const showMixinToast = (title: string, icon: SweetAlertIcon, position?: SweetAlertPosition) => {

    const setBackgroundColor = () => {

        switch (icon) {

            case 'success':
                return '#a5dc86'
            case 'error':
                return '#f27474'
            case 'warning':
                //     return '#f8bb86'
                return '#d90b0b'
            case 'info':
                return '#3fc3ee'
            case 'question':
                return '#87adbd'

        }

    }

    const Toast = Swal.mixin({
        toast: true,
        position: position ?? 'bottom',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        iconColor: 'white',
        customClass: {
            popup: 'colored-toast',
            title: 'toast-title',
        },
        background: setBackgroundColor(),
        color: 'white',
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: icon,
        title: title
    })

}

export const showModalMessage = (title: string, description: string, icon: SweetAlertIcon) => {

    Swal.fire(
        title,
        description,
        icon,
    )

}

interface ModalConfirmProps {
    title: string,
    description: string,
    icon: SweetAlertIcon,
    handleConfirm: () => void,
    confirmButtonText?: string,
    confirmButtonColor?: string,
    cancelButtonColor?: string,
}

export const showModalConfirm = ({
    title,
    description,
    icon,
    confirmButtonText,
    confirmButtonColor,
    cancelButtonColor,
    handleConfirm
}: ModalConfirmProps) => {

    Swal.fire({
        title,
        text: description,
        icon,
        customClass: {
            title: 'toast-title',
            footer: 'toast-title',
            container: 'toast-title',
            htmlContainer: 'toast-title',
            confirmButton: 'toast-title',

        },
        showCancelButton: false,
        confirmButtonColor: confirmButtonColor ?? '#d33',
        cancelButtonColor: cancelButtonColor ?? '#3085d6',
        cancelButtonText: 'Cancelar',
        confirmButtonText: confirmButtonText ?? 'Confirmar'
    }).then((result) => {
        if (result.isConfirmed) {
            handleConfirm()
        }
    })

}