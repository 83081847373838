import styled from "styled-components"
import Logos from "./Logos"

const Container = styled.div`
    width: 100vw;
    height: 100vh;
    height: 75vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

export const Introduction = () => {

    return (

        <Container>

            <Logos />

        </Container >


    )
}

export default Introduction;