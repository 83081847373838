import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts, TicketProps } from "../constants/TextConstants"
import { useLogEvent } from "../hooks/useLogEvent"
import { useState } from "react"

const Container = styled.div`
    max-width: 1000px;
    width: 90%;
    /* padding: 5vh 5% 15vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: center;
    position: relative;
    z-index: 1;
    margin-top: 200px;
    
    @media ${device.lg} {
        width: 80%;
        margin-top: 100px;
    }

    @media ${device.sm} {
        width: 90%;
        margin-top: 70px;
    }

`

const Paragraph = styled.p`
    color: white;
    font-size: 30px;
    letter-spacing: 5px;
    line-height: 45px;
    margin-bottom: 50px;
    width: 100%;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;

    & > span {
        color: #D90B0B;
        font-weight: 600;
        font-size: 36px;
    }

    @media ${device.lg} {
        font-size: 24px;
        letter-spacing: 3px;
        line-height: 37px;
        margin-bottom: 50px;

        & > span {
            font-size: 30px;
        }
    }

    @media ${device.md} {
        font-size: 18px;
        letter-spacing: 1px;
        line-height: 30px;
        margin-bottom: 20px;

        & > span {
            font-size: 24px;
        }
    }

    @media ${device.sm} {
        font-size: 13px;
        letter-spacing: 1px;
        line-height: 25px;
        margin-bottom: 15px;

        & > span {
            font-size: 18px;
        }
    }

    @media ${device.xs} {
        font-size: 9px;
        letter-spacing: 1px;
        line-height: 20px;
        margin-bottom: 10px;

        & > span {
            font-size: 14px;
        }
    }
`

const SendButton = styled.button`
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #111;
    background-color: white;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 10px;
    z-index: 2;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #EEE;
        transform: scale(1.02);
    }

    @media ${device.xl} {
        font-size: 18px;
        padding: 15px 30px;

    }

    @media ${device.lg} {
        font-size: 14px;
        padding: 12.5px 30px;
    }

    @media ${device.md} {
        margin-right: 0px;
    }

    @media ${device.sm} {
        font-size: 14px;
        padding: 7.5px 20px;
    }

    @media ${device.xs} {
        font-size: 12px;
        padding: 7.5px 20px;
    }

`

const Arrow = styled.img`
    width: 35px;
    height: auto;
    margin-left: 20px;

    @media ${device.sm} {
        width: 25px;
    }

    @media ${device.sm} {
        width: 20px;
        margin-left: 15px;
    }

`
interface Props {
    ticket: TicketProps;
}

export const InfoFest = ({ ticket }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const [socialImages] = useState({
        // orangeArrow: require('../images/introductionTickets/arrow-orange.png'),
        // redArrow: require('../images/introductionTickets/arrow-red.png'),
        orangeArrow: `${process.env.REACT_APP_CDN}/arrow-orange.png`,
        redArrow: `${process.env.REACT_APP_CDN}/arrow-red.png`,
    });


    return (

        <Container>

            <Paragraph className="utils__fade-in-fast-top">
                FIEBRE ES LA UNICA FIESTA 100%<br />
                <span>REGGAETON OLD SCHOOL</span>,<br />
                CREADA ESPECIALMENTE PARA LOS<br />
                AMANTES DEL <span>FLOW</span> Y LA<br />
                <span>NOSTALGIA</span>.
            </Paragraph>

            <SendButton
                onClick={(e) => {

                    e.preventDefault();

                    window.open(ticket.ticketLink, "_blank");

                    handleLogEvent(`click_boton_entrada_${ticket.ticketEvent}`)
                }}
            >
                QUIERO MI ENTRADA
                {
                    socialImages.redArrow ? (

                        <Arrow loading="lazy" src={socialImages.redArrow} alt="flecha" />

                    ) : <></>
                }
            </SendButton>

        </Container>

    )
}

export default InfoFest;