import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useDispatch } from "react-redux"
import { useLogEvent } from "../hooks/useLogEvent"
import { useHistory } from "react-router-dom"
import { globalTexts } from "../constants/TextConstants"

const Container = styled.section`
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 5vh;
    margin-bottom: 20vh;

    @media ${device.lg} {
        margin-top: 0vh;
        margin-bottom: 10vh;
    }

    @media ${device.sm} {
        padding-top: 5vh;
        margin-bottom: 10vh;
    }

`

const Title = styled.p`
    width: 100%;
    font-weight: 900;
    font-size: 38px;
    color: #FFF;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    margin-bottom: 7vh;
    letter-spacing: 15px;
    line-height: 70px;

    @media ${device.lg} {
        font-size: 28px;
        letter-spacing: 10px;
        line-height: 50px;
        margin-bottom: 4vh;
    }

    @media ${device.md} {
        margin-bottom: 3vh;
        font-size: 20px;
        letter-spacing: 10px;
        line-height: 40px;
        text-align: center;
    }

    @media ${device.sm} {
        margin-bottom: 4vh;
        font-size: 18px;
        letter-spacing: 5px;
    }

    @media ${device.xs} {
        font-size: 14px;
    }
`

const SendButton = styled.button`
    position: relative;
    font-size: 16px;
    font-weight: bold;
    color: #111;
    background-color: #3FA856;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 20px 40px;
    border-radius: 10px;
    margin-top: 0px;
    z-index: 3;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #EEE;
        transform: scale(1.02);
    }

    @media ${device.lg} {
        font-size: 14px;
        padding: 20px 30px;
    }

    @media ${device.md} {
        padding: 15px 25px;
        font-size: 12px;
    }

    @media ${device.sm} {
        font-size: 10px;
    }

    @media ${device.xs} {
        font-size: 8px;
    }

`

const Picture = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0vh;
    right: 3vw;
    z-index: 0;

    @media ${device.lg} {
        width: 15vh;
    }
  
`

const LogoYellowFire = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0;
    left: -2vh;
    z-index: 0;

    @media ${device.lg} {
        top: 5vh;
        width: 12vh;
    }

    @media ${device.md} {
        top: 15vh;
    }

    @media ${device.sm} {
        top: 20vh;
        width: 10vh;
    }

`

const YellowFire = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -25vh;
    right: 0;
    z-index: 0;

    @media ${device.lg} {
        width: 10vh;
        top: -20vh;
    }

    @media ${device.sm} {
        top: -8vh;
        width: 8vh;
    }

`

const BrockenFiebre = styled.img`
    width: 200px;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -100px;
    right: 20%;
    z-index: 0;

    @media ${device.lg} {
        width: 180px;
        right: 15%;
    }

    @media ${device.md} {
        bottom: -80px;
        width: 150px;
        right: 10%;
    }

    @media ${device.sm} {
        bottom: -60px;
        width: 100px;
        right: 0%;
    }


`

const Arrow = styled.img`
    width: 120px;
    height: auto;
    margin-left: 30px;

    @media ${device.lg} {
        width: 100px;
    }

    @media ${device.sm} {
        width: 50px;
    }

    @media ${device.xs} {
        margin-left: 20px;
    }
`

export const WhatsAppGroup = () => {

    const dispatch = useDispatch();

    const history = useHistory();

    const { handleLogEvent } = useLogEvent();

    return (

        <Container>

            <Title className='utils__fade-in-fast-top'>(Solo apto manijas)</Title>

            <YellowFire
                className='utils__fade-in-fast-left'
                // src={require(`../images/stickers/fuego-amarillo.png`)}
                src={`${process.env.REACT_APP_CDN}/fuego-amarillo-derecha.png`}
                alt="YellowFire"
            />

            <LogoYellowFire
                className='utils__fade-in-fast-right'
                // src={require(`../images/stickers/fuego-amarillo.png`)}
                src={`${process.env.REACT_APP_CDN}/fiebre-amarillo2.png`}
                alt="YellowFire"
            />

            <SendButton
                className="utils__fade-in-fast-top"
                onClick={(e) => {

                    window.open(globalTexts.whatsAppGroupLink, "_blank");

                    handleLogEvent('click_boton_grupo_whatsapp')
                }}
            >
                Unite al grupo de whatsapp

                <Arrow loading="lazy" src={`${process.env.REACT_APP_CDN}/flecha-derecha-larga.png`} alt="flecha" />

            </SendButton>


            <BrockenFiebre
                className='utils__fade-in-fast-top'
                // src={require(`../images/stickers/termometro-roto.png`)}
                src={`${process.env.REACT_APP_CDN}/fiebre-roto.png`}
                alt="Fiebre"
            />

        </Container >


    )
}

export default WhatsAppGroup;