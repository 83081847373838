import { memo } from "react";
import { useLogEvent } from "../hooks/useLogEvent";
import styled from "styled-components";
import { device } from "../styles/stylesConfig";

const WsButton = styled.a`
    width: 55px;
    height: 55px;
    border-radius: 50px;
    background: #D90B0B;
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

    & > img {
        width: 30px;
        height: 30px;
        padding-left: 2px;
    }

    @media ${device.sm} {
        right: 30px;
    }

`

const WsFloatButtonComponent = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <WsButton
            className="utils__fade-in-fast-left"
            target="_blank"
            href="https://wa.me/+5491170350190"
            rel="noreferrer"
            onClick={() => {
                handleLogEvent('Click_boton_whatsapp_flotante');
            }}
        >

            <img
                loading="lazy"
                src={`${process.env.REACT_APP_CDN}/whatsapp.webp`}
                alt='Logo WhatsApp'
            />

        </WsButton>

    )
}

const WsFloatButton = memo(WsFloatButtonComponent);

export default WsFloatButton;
