import styled from "styled-components"
import { device } from "../styles/stylesConfig"


const Container = styled.div`
    width: 100vw;
    padding: 20vh 0 10vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0;

    }
`

const InfoContainer = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 950px; 
    width: 80%;
    border-radius: 15px;
    padding: 50px;
    background-color: rgba(239, 239, 239, 0.5);
    position: relative;

    p:last-of-type {
        margin-bottom: 0 !important;
    }

    @media ${device.xl} {
        max-width: 780px; 
    }

    @media ${device.md} {
        padding: 50px 0px;
    }

`

const Paragraph = styled.p`
    width: 100%;
    font-weight: 900;
    font-size: 28px;
    color: #111;
    margin-bottom: 40px;
    
    @media ${device.xl} {
        font-size: 22px;
    }

    @media ${device.lg} {
        font-size: 18px;
    }

    @media ${device.md} {
        width: 85%;
        font-size: 14px;
        margin-bottom: 30px;
    }

    @media ${device.sm} {
        font-size: 12px;
        margin-bottom: 20px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }
`

const RedRadio = styled.img`
    max-width: 40%;
    width: 18vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -7vh;
    left: -14vh;
    z-index: 3;

    @media ${device.lg} {
        width: 12vh;
        top: -5vh;
        bottom: unset;
        left: -5vh;
        transform: rotate(-20deg);
    }

    @media ${device.sm} {
        width: 10vh;
        top: -4vh;
        left: -3vh;
    }

`

const Barcode = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -6.5vh;
    right: 1vw;
    z-index: 1;
    transform: rotate(2deg);

    @media ${device.lg} {
        width: 13vh;
        bottom: -5.5vh;
    }
    
    @media ${device.md} {
        width: 12vh;
        bottom: -5.2vh;
    }

    @media ${device.sm} {
        width: 10vh;
        bottom: -4.5vh;
    }
`

const Heart = styled.img`
    width: 24vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -7.5vh;
    right: -3vh;
    z-index: 1;

    @media ${device.lg} {
        width: 18vh;
        top: unset;
        right: unset;
        bottom: -11vh;
        left: -8vh;
    }

    @media ${device.sm} {
        width: 14vh;
        bottom: -8vh;
        left: -6vh;
    }

    @media ${device.xs} {
        bottom: -6vh;
    }
`

const ThinTapeBottom = styled.img`
    width: 35vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -10vh;
    right: -6vw;
    z-index: 2;

    @media ${device.lg} {
        width: 25vh;
        bottom: -7vh;
        right: -10vw;
    }
    
    @media ${device.sm} {
        bottom: -7vh;
        right: -14vw;
    }
`

const ThinTapeTop = styled.img`
    width: 35vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -11vh;
    left: -7vw;
    z-index: 2;
    transform: rotate(180deg);

    @media ${device.lg} {
        width: 25vh;
        top: -9vh;
        left: -11vw;
    }
    
    @media ${device.sm} {
        top: -9vh;
        left: -15vw;
    }
`

const Fashion = styled.img`
    width: 30vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -16vh;
    left: -22vw;
    z-index: 2;

    @media ${device.lg} {
        width: 20vh;
        top: -16vh;
        left: unset;
        right: -14vw;
    }
    
    @media ${device.sm} {
        top: -14vh;
        right: -14vw;
    }

`

export const About = () => {

    return (

        <Container>

            <InfoContainer>

                <Paragraph className="utils__fade-in-top">TE IMAGINAS UNA FIESTA EXCLUSIVA DE REGGAETON VIEJO?</Paragraph>

                <Paragraph className="utils__fade-in-top">BIENVENIDOS A <span style={{ color: '#D90B0B', fontStyle: 'italic' }}>FIEBRE</span></Paragraph>

                <Paragraph className="utils__fade-in-top">SOMOS CREYENTES DE QUE EL REGGAETON DE LA VIEJA ESCUELA NO PASO DE MODA.</Paragraph>

                <Paragraph className="utils__fade-in-top" style={{ textDecoration: 'underline' }}>CREAMOS ESTA EXPERIENCIA PARA LOS VERDADEROS AMANTES DEL FLOW Y LA NOSTALGIA.</Paragraph>

                <Paragraph className="utils__fade-in-top">¿TE IMAGINAS UNA NOCHE DONDE LOS TEMONES CLASICOS DE REGGAETON SON LOS PROTAGONISTAS?.</Paragraph>

                <Paragraph className="utils__fade-in-top">PREPARATE PARA VIVIRLA EN <span style={{ color: '#DAA217', fontStyle: 'italic' }}>FIEBRE</span>.</Paragraph>

                <Paragraph className="utils__fade-in-top">PERREO TODA LA NOCHE.</Paragraph>

                <RedRadio
                    className="utils__fade-in-fast-top"
                    // src={require('../images/stickers/radio-roja.png')}
                    src={`${process.env.REACT_APP_CDN}/radio-roja.webp`}
                    alt="Radio roja"
                />

                <Barcode
                    className="utils__fade-in-fast-left"
                    // src={require(`../images/introduction/barcode.png`)}
                    src={`${process.env.REACT_APP_CDN}/barcode.png`}
                    alt="Barcode"
                />

                <Heart
                    className="utils__fade-in-fast-left"
                    src={`${process.env.REACT_APP_CDN}/corazon-llama.webp`}
                    alt="Corazon"
                />

                <ThinTapeBottom
                    className="utils__fade-in-fast-top"
                    // src={require(`../images/stickers/cinta-fina.png`)}
                    src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                    alt="Cinta"
                />

                <ThinTapeTop
                    className="utils__fade-in-fast-bottom"
                    // src={require(`../images/stickers/cinta-fina.png`)}
                    src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                    alt="Cinta"
                />


                <Fashion
                    className="utils__fade-in-fast-center"
                    // src={require(`../images/stickers/moda.png`)}
                    src={`${process.env.REACT_APP_CDN}/moda.webp`}
                    alt="Moda"
                />

            </InfoContainer>


        </Container>

    )
}

export default About;