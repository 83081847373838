import { memo, useState } from "react"
import styled from "styled-components";
import { device } from "../styles/stylesConfig";
import { useLogEvent } from "../hooks/useLogEvent";
import { TicketProps, globalTexts } from "../constants/TextConstants";
import { handleClickWantMyTable } from "../helpers/TicektsHelper";

const LogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;

    & > button:first-child {
        margin-top: unset;
        align-self: start;
    }
`

const SendButton = styled.button`
    position: relative;
    font-size: 22px;
    font-weight: bold;
    color: #111;
    background-color: white;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 30px;
    align-self: self-start;
    z-index: 2;
    box-shadow: 0px 2px 12px 0px rgba(255, 255, 255, 0.2);

    &:hover {
        background-color: #EEE;
        transform: scale(1.02);
    }

    @media ${device.xl} {
        font-size: 18px;
        padding: 15px 30px;

    }

    @media ${device.lg} {
        font-size: 14px;
        padding: 12.5px 30px;
        margin-right: 20px;
    }

    @media ${device.md} {
        margin-top: 20px;
        margin-right: 0px;
    }

    @media ${device.sm} {
        font-size: 14px;
        padding: 7.5px 20px;
        margin-top: 10px;
    }

    @media ${device.xs} {
        font-size: 12px;
        padding: 7.5px 20px;
    }

`

const Arrow = styled.img`
    width: 35px;
    height: auto;
    margin-left: 20px;

    @media ${device.sm} {
        width: 25px;
    }

    @media ${device.sm} {
        width: 20px;
        margin-left: 15px;
    }

`

interface Props {
    ticket: TicketProps
}

const WantMyTicketTableButtonsComponent = ({ ticket }: Props) => {

    const { handleLogEvent } = useLogEvent();

    const [socialImages] = useState({
        // orangeArrow: require('../images/introductionTickets/arrow-orange.png'),
        // redArrow: require('../images/introductionTickets/arrow-red.png'),
        orangeArrow: `${process.env.REACT_APP_CDN}/arrow-orange.png`,
        redArrow: `${process.env.REACT_APP_CDN}/arrow-red.png`,
    });

    return (

        <LogoContainer className="utils__fade-in-left">

            <SendButton
                onClick={(e) => {

                    e.preventDefault();

                    window.open(ticket.ticketLink, "_blank");

                    handleLogEvent(`click_boton_entrada_${ticket.ticketEvent}`)
                }}
            >
                QUIERO MI ENTRADA
                {
                    socialImages.redArrow ? (

                        <Arrow loading="lazy" src={socialImages.redArrow} alt="flecha" />

                    ) : <></>
                }
            </SendButton>

            <SendButton
                onClick={(e) => {

                    e.preventDefault();

                    handleClickWantMyTable(
                        ticket.tablesLink,
                        ticket.ticketEvent,
                        handleLogEvent
                    )
                }}
            >
                QUIERO MI MESA
                {
                    socialImages.orangeArrow ? (

                        <Arrow loading="lazy" src={socialImages.orangeArrow} alt="flecha" />

                    ) : <></>
                }
            </SendButton>



        </LogoContainer>
    )
}

const WantMyTicketTableButtons = memo(WantMyTicketTableButtonsComponent);

export default WantMyTicketTableButtons;