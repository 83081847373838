import { showMixinToast } from "../utils/Alerts";

export const handleClickWantMyTable = (tablesLink: string, ticketEvent: string, handleLogEvent: any) => {

    if (process.env.REACT_APP_NO_TABLES && process.env.REACT_APP_NO_TABLES === '1') {

        showMixinToast('No hay mesas disponibles', 'warning');

    } else {

        window.open(tablesLink, "_blank");

        handleLogEvent(`click_boton_mesa_${ticketEvent}`)

    }

}