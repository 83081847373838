import { useEffect, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { allAnimations } from "../effects/Observers";
import ScreenContainer from "../components/ScreenContainer"
import Building from "../components/Building";
import { getFullDate } from "../utils/Utilities";
import Rights from "../components/Rights";
import Location from "../components/Location";
import HeaderTicket from "../components/HeaderTicket";
import Social from "../components/Social";
import IntroductionTickets from "../components/IntroductionTickets";
import { useSelector } from "react-redux";
import WhatsAppGroup from "../components/WhatsAppGroup";
import { TicketProps, globalTexts } from "../constants/TextConstants";
import IntroductionTicketsBlack from "../components/IntroductionTicketsBlack";
import WsFloatButton from "../components/WsFloatButton";

export const TicketScreen = () => {

    const { loading } = useSelector((state: any) => state.ui);

    const location = useLocation()

    const history = useHistory()

    const [event, setEvent] = useState<TicketProps | undefined>(globalTexts.tickets.find((value) => { return value.goTo === location.pathname.replace('/', '') }))

    if (event === undefined) history.push('/');

    const [showPreloader, setShowPreloader] = useState(true);

    const [passwordOk, setPasswordOk] = useState((localStorage.getItem('passwordExpire') !== undefined && localStorage.getItem('passwordExpire') !== null && parseInt(getFullDate()) < parseInt(localStorage.getItem('passwordExpire')!)) ?? false);


    // const { pathname } = useLocation();

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [pathname]);


    const preloader = () => {

        if (!loading) {

            setTimeout(() => {

                setShowPreloader(false);

            }, 3900);

        }

    };

    useEffect(() => {

        preloader();

        allAnimations();

        return () => { allAnimations() };

    }, [location.pathname, loading])

    if (process.env.REACT_APP_IN_PROGRESS === '1' && !passwordOk) return <Building showInProgress preloader={false} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />

    return (

        <ScreenContainer>

            {showPreloader && <Building showInProgress={false} preloader={true} passwordOk={passwordOk} setPasswordOk={setPasswordOk} />}

            <WsFloatButton />

            <HeaderTicket />

            {
                event?.goTo && event.goTo.includes('black')
                    ? <IntroductionTicketsBlack ticket={event} />

                    : event?.goTo ? <IntroductionTickets ticket={event} /> : <></>

            }

            {
                event?.locationLink && <Location link={event?.locationLink} />
            }

            <WhatsAppGroup />

            <Social />

            <Rights brand="Fiebre Fiesta" backgroundColor="rgba(0, 0, 0, 0.25)" />

        </ScreenContainer>

    )
}
