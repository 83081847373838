import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { useState } from "react"
import { globalTexts } from "../constants/TextConstants"

const SocialContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 10vh;

    @media ${device.lg} {
        margin-bottom: 5vh;
    }
`

const IconButtonsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1.5fr);

    @media ${device.lg} {
        grid-template-columns: repeat(2, 1fr);
    }
`

const Button = styled.button<{ dimension?: 'mobile' | 'desktop' }>`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 30px;
    transition: all 0.3s ease-in-out;
    border-radius: 50px;
    cursor: pointer;
    margin: 0 50px;

    &:hover {
        background-color: rgba(255,255,255,0.2);
    }

    @media ${device.xl} {
        margin: 0 30px;
    }

    @media ${device.lg} {
        margin: 0 20px;
    }

    @media ${device.md} {
        margin: 0 10px;
    }

    @media ${device.sm} {
        margin: 0px;
        padding: 10px 20px;
    }
    
    @media ${device.xs} {
        margin: 0px;
    }

    ${({ dimension }) => dimension === 'desktop' && css`
        display: flex;

        @media ${device.lg} {
            display: none;
        }

    `}

    ${({ dimension }) => dimension === 'mobile' && css`
        display: none;
      
        @media ${device.lg} {
            display: flex;
            margin-bottom: 20px;
        }

        @media ${device.sm} {
            margin-bottom: 10px;
        }
    `}

`

const IconButton = styled.img`
    width: 40px;
    height: auto;
    object-fit: contain;
    transition: all 0.3s ease-in-out;

    @media ${device.lg} {
        width: 35px;
    }

    @media ${device.md} {
        width: 30px;
    }

    @media ${device.sm} {
        width: 25px;
    }

    @media ${device.xs} {
        width: 20px;
    }
`

const IconTitle = styled.p`
    font-size: 22px;
    margin-left: 15px;
    color: white;
    transition: all 0.3s ease-in-out;

    @media ${device.lg} {
        font-size: 18px;
    }

    @media ${device.md} {
        font-size: 16px;
    }

    @media ${device.sm} {
        font-size: 14px;
    }

    @media ${device.xs} {
        font-size: 12px;
        margin-left: 10px;
    }
`


export const Social = () => {

    const { handleLogEvent } = useLogEvent();

    const [socialImages] = useState({
        // twitter: require('../images/social/twitter-blanco.png'),
        // instagram: require('../images/social/ig-blanco.png'),
        // tiktok: require('../images/social/tiktok-blanco.png'),
        twitter: `${process.env.REACT_APP_CDN}/twitter-blanco.png`,
        instagram: `${process.env.REACT_APP_CDN}/ig-blanco.png`,
        tiktok: `${process.env.REACT_APP_CDN}/tiktok-blanco.png`,
    });

    return (

        <SocialContainer>
            {/* 
            <Button
                dimension="mobile"
                className="utils__fade-in-fast-center"
                onClick={(e) => {

                    window.open(globalTexts.instagramLink, "_blank");

                    handleLogEvent('click_boton_instagram')
                }}
            >

                {
                    socialImages.instagram ? (

                        <IconButton loading="lazy" src={socialImages.instagram} alt="instagram" />

                    ) : <></>
                }

                <IconTitle>@fiebreflow</IconTitle>

            </Button> */}


            <IconButtonsContainer>


                <Button
                    // dimension="desktop"
                    className="utils__fade-in-fast-top"
                    onClick={(e) => {

                        window.open(globalTexts.instagramLink, "_blank");

                        handleLogEvent('click_boton_instagram')
                    }}
                >

                    {
                        socialImages.instagram ? (

                            <IconButton loading="lazy" src={socialImages.instagram} alt="instagram" />

                        ) : <></>
                    }

                    <IconTitle>@fiebreflow</IconTitle>

                </Button>

                <Button
                    className="utils__fade-in-fast-top"
                    onClick={(e) => {

                        window.open(globalTexts.tiktokLink, "_blank");

                        handleLogEvent('click_boton_tiktok')
                    }}
                >

                    {
                        socialImages.tiktok ? (

                            <IconButton loading="lazy" src={socialImages.tiktok} alt="tiktok" />

                        ) : <></>
                    }

                    <IconTitle>fiebre.fiesta</IconTitle>

                </Button>

                {/* <Button
                    className="utils__fade-in-fast-top"
                    onClick={(e) => {

                        window.open(globalTexts.twitterLink, "_blank");

                        handleLogEvent('click_boton_twitter')
                    }}
                >
                    {
                        socialImages.twitter ? (

                            <IconButton loading="lazy" src={socialImages.twitter} alt="twitter" />

                        ) : <></>
                    }

                    <IconTitle>fiebrefiesta</IconTitle>

                </Button> */}

            </IconButtonsContainer>

        </SocialContainer>

    )
}

export default Social;