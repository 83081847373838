import styled, { css } from "styled-components"
import { device } from "../styles/stylesConfig"
import { globalTexts, TicketProps } from "../constants/TextConstants"
import Ticket from "./Ticket"
import { getCurrentUTCTime, getTicketsList } from "../utils/Utilities"

const Container = styled.section`
    width: 100vw;
    padding: 10vh 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
`

const Title = styled.p`
    width: 100%;
    font-weight: 900;
    font-size: 42px;
    color: #FFF;
    margin-bottom: 40px;
    text-transform: uppercase;
    text-align: center;
    z-index: 2;
    margin-bottom: 7vh;
    letter-spacing: 15px;
    line-height: 70px;

    @media ${device.lg} {
        font-size: 30px;
        letter-spacing: 10px;
        line-height: 50px;
        margin-bottom: 4vh;
    }

    @media ${device.md} {
        margin-top: 5vh;
        margin-bottom: 3vh;
        font-size: 22px;
        letter-spacing: 10px;
        line-height: 40px;
        text-align: center;
    }

    @media ${device.sm} {
        margin-top: 10vh;
        margin-bottom: 4vh;
        font-size: 18px;
        letter-spacing: 7px;
    }

    @media ${device.xs} {
        margin-top: 7vh;
        font-size: 14px;
    }
`

const TicketsList = styled.div<{ listlength: number }>`
    width: auto;
    height: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    position: relative;
    grid-template-columns: repeat(2, 1fr);
    gap: 100px;

    ${props => props.listlength % 2 !== 0 && css`

        & > div:nth-of-type(${props.listlength}) {
            grid-column: 1 / 3;
        }

        
    @media ${device.md} {
        & > div:nth-of-type(${props.listlength}) {
            grid-column: unset;
        }
    }

    `}

    @media ${device.md} {
        grid-template-columns: repeat(1, 1fr);
    }

    @media ${device.md} {
        gap: 70px;
    }

`

const Picture = styled.img`
    width: 25vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 0vh;
    right: 3vw;
    z-index: 0;

    @media ${device.lg} {
        width: 15vh;
    }
  
`

const Dance = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 5vh;
    left: 5vw;
    z-index: 0;

    @media ${device.lg} {
        width: 15vh;
    }

`

const YellowFire = styled.img`
    width: 15vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: 10vh;
    bottom: 0;
    left: 0;
    z-index: 0;

    @media ${device.lg} {
        width: 10vh;
    }

    @media ${device.sm} {
        width: 8vh;
    }

`

const Temperature = styled.img`
    width: 20vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: 0vh;
    right: 0;
    z-index: 0;

    @media ${device.lg} {
        width: 15vh;
    }


`


const Tape = styled.img`
    width: 350px;
    height: auto;
    position: absolute;
    margin: auto;
    top: -130px;
    left: 0;
    right: 0;
    z-index: 1;
    transform: rotate(35deg);

    @media ${device.lg} {
        width: 300px;
        top: -110px;
    }
    
    @media ${device.md} {
        width: 200px;
        top: -75px;
    }

    @media ${device.sm} {
        width: 150px;
        top: -60px;
    }

    @media ${device.xs} {
        width: 100px;
        top: -40px;
    }

`

export const TicketSection = () => {

    const getListLength = () => {

        const list = getTicketsList(globalTexts.tickets).filter((value: TicketProps) => {

            const targetTimeUTC = new Date(value.showSince);

            const currentTimeUTC = new Date(getCurrentUTCTime());

            if (currentTimeUTC >= targetTimeUTC) {
                return value
            }
        })

        return list.length
    }

    return (

        <Container>

            <Title className='utils__fade-in-fast-top'>Próximas fechas</Title>

            <TicketsList listlength={getListLength()}>
                {
                    getTicketsList(globalTexts.tickets).map((ticket: TicketProps) => {

                        const targetTimeUTC = new Date(ticket.showSince);

                        const currentTimeUTC = new Date(getCurrentUTCTime());

                        if (currentTimeUTC >= targetTimeUTC) {

                        } else return

                        return (

                            <Ticket ticket={ticket} key={ticket.flyer} />

                        )

                    })
                }

            </TicketsList>

            <Picture
                className='utils__fade-in-fast-left'
                // src={require(`../images/stickers/picture.png`)}
                src={`${process.env.REACT_APP_CDN}/picture.webp`}
                alt="Picture"
            />

            <Dance
                className='utils__fade-in-fast-center'
                // src={require(`../images/stickers/perreo-roto.png`)}
                src={`${process.env.REACT_APP_CDN}/perreo-roto.webp`}
                alt="Dance"
            />

            <YellowFire
                className='utils__fade-in-fast-right'
                // src={require(`../images/stickers/fuego-amarillo.png`)}
                src={`${process.env.REACT_APP_CDN}/fuego-amarillo.png`}
                alt="YellowFire"
            />

            <Temperature
                className='utils__fade-in-fast-top'
                // src={require(`../images/stickers/termometro-roto.png`)}
                src={`${process.env.REACT_APP_CDN}/termometro-roto.png`}
                alt="Temperature"
            />

        </Container >


    )
}

export default TicketSection;