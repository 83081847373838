import styled from "styled-components"
import { device } from "../styles/stylesConfig"

const Container = styled.div`
    text-align: center;
    margin-bottom: 30px;
`

const ContentContainer = styled.div`
  overflow: hidden;

`

const List = styled.div`

    @keyframes desplazar {
        from {
        transform: translateX(0);
        }
        to {
            transform: translateX(-75%);
        }
    }

    display: flex;
    animation: desplazar 60s linear infinite;

    @media ${device.lg} {
        animation: desplazar 60s linear infinite;
    }

    @media ${device.md} {
        animation: desplazar 40s linear infinite;
    }

    @media ${device.sm} {
        animation: desplazar 30s linear infinite;
    }

    @media ${device.xs} {
        animation: desplazar 20s linear infinite;
    } 
  
`

const Icon = styled.img`
    height: 100px;
    width: auto;
    transform: rotate(-0.16deg);
    margin-right: -28px;

    @media ${device.md} {
        height: 70px;
        margin-right: -20px;
    }

    @media ${device.sm} {
        height: 50px;
        margin-right: -12px;
    }

`


export const DanceZone = () => {

    return (

        <Container>

            <ContentContainer>

                <List>

                    {[...Array(6)].map((item, index) => (

                        <Icon
                            key={index}
                            // src={require(`../images/stickers/dance-zone.png`)}
                            src={`${process.env.REACT_APP_CDN}/dance-zone.webp`}
                            alt="ZONA DE PERREO"
                        />
                    ))}

                </List>

            </ContentContainer>

        </Container>

    )
}

export default DanceZone