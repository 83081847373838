import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import { useLogEvent } from "../hooks/useLogEvent"
import { useState } from "react"

const SpotifyContainer = styled.div`
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 30px 30px;
    border-radius: 15px;
    box-shadow: 0px 4px 20px 0px rgba(255, 255, 255, 0.1);
    background-color: rgba(239, 239, 239, 0.5);
    margin-top: 15vh;
    margin-bottom: 20vh;

    @media ${device.lg} {
        margin-bottom: 15vh;
    }

    @media ${device.md} {
        padding: 20px;
    }

    @media ${device.sm} {
        width: 70%; 
        margin-bottom: 10vh;
    }

    @media ${device.xs} {
        margin-bottom: 7.3vh;
    }
`

const SpotifyLogo = styled.img`
    width: 75px;
    height: auto;
    object-fit: contain;
    margin-bottom: 30px;
    align-self: start;
    z-index: 2;

    @media ${device.lg} {
        width: 60px;
    }

    @media ${device.md} {
        width: 50px;
        margin-bottom: 20px;
    }

    @media ${device.sm} {
        width: 40px;
        margin-bottom: 10px;
    }
    @media ${device.xs} {
        width: 35px;
    }
`

const Title = styled.h2`
    color: black;
    font-size: 40px;
    letter-spacing: 15px;
    line-height: 70px;
    z-index: 2;
    margin: 0px 200px 0px 50px;

    @media ${device.lg} {
        font-size: 30px;
        letter-spacing: 10px;
        line-height: 50px;
        margin: 0px 100px 0px 50px;
    }

    @media ${device.md} {
        font-size: 22px;
        letter-spacing: 10px;
        line-height: 40px;
        text-align: center;
        margin: 0 50px;
    }

    @media ${device.sm} {
        font-size: 18px;
        letter-spacing: 5px;
        margin: 0 20px;
    }

    @media ${device.xs} {
        font-size: 14px;
        letter-spacing: 3px;
        line-height: 30px;
    }
`

const SendButton = styled.button`
    font-family: 'Montserrat', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    position: relative;
    font-size: 14px;
    font-weight: 700;
    color: #111;
    background-color: #D90B0B;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 20px 30px;
    border-radius: 10px;
    margin-top: 30px;
    margin-right: 40px;
    align-self: end;
    z-index: 2;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 2px;
        height: 100%;
        width: 0%;
        background: rgba(0, 0, 0, 0.1);
    }

    &:hover {
        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);

        &::before {
            animation: fillButton 0.3s ease-in-out forwards;
        }
    }

    @media ${device.lg} {
        font-size: 12px;
        padding: 12.5px 30px;
        margin-right: 20px;
    }

    @media ${device.md} {
        margin-top: 30px;
        margin-right: 0px;
        align-self: center;
    }

    @media ${device.sm} {
        font-size: 10px;
        margin-top: 20px;
    }

    @media ${device.xs} {
        font-size: 10px;
    }

`

const Tape = styled.img`
    width: 35vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -7vh;
    left: 0px;
    right: 30px;
    z-index: 2;

    @media ${device.lg} {
        width: 25vh;
        top: -6vh;
        left: 0px;
        right: 30px;
    }
    
    @media ${device.sm} {
        width: 20vh;
        top: -4.5vh;
        left: 0px;
        right: 0px;
    }
`

const RedFire = styled.img`
    width: 10vh;
    height: auto;
    position: absolute;
    margin: auto;
    top: -8.5vh;
    right: -5vh;
    z-index: 2;

    @media ${device.lg} {
        width: 8vh;
        top: -7vh;
        right: -4vh;
    }

    @media ${device.sm} {
        width: 7vh;
        top: -6vh;
        right: -4vh;
    }

`

const OldSchool = styled.img`
    width: 18vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -8.5vh;
    left: -5vh;
    z-index: 2;

    @media ${device.lg} {
        width: 12vh;
        bottom: -5vh;
        left: -5vh;
    }

    @media ${device.sm} {
        width: 10vh;
        bottom: -4vh;
        left: -4vh;
    }

`

const Arrow = styled.img`
    width: 50px;
    height: auto;
    margin-left: 50px;

    @media ${device.lg} {
        width: 40px;
        margin-left: 30px;
    }

    @media ${device.sm} {
        width: 35px;
    }

    @media ${device.xs} {
        width: 30px;
        margin-left: 20px;
    }
`

export const Spotify = () => {

    const { handleLogEvent } = useLogEvent();

    const [socialImages] = useState({
        // spotify: require('../images/social/spotify.png'),
        // arrow: require('../images/social/flecha-derecha.webp'),
        spotify: `${process.env.REACT_APP_CDN}/spotify.png`,
        arrow: `${process.env.REACT_APP_CDN}/flecha-derecha.webp`,
    });

    return (

        <SpotifyContainer id="social">

            <Tape
                className="utils__fade-in-fast-top"
                // src={require(`../images/stickers/cinta-fina-marron.png`)}
                src={`${process.env.REACT_APP_CDN}/cinta-fina-marron.webp`}
                alt="Cinta"
            />

            <RedFire
                className="utils__fade-in-fast-left"
                // src={require('../images/stickers/fiebre-llama-roja.png')}
                src={`${process.env.REACT_APP_CDN}/fiebre-llama-roja.png`}
                alt="Fiebre llamas"
            />

            <OldSchool
                className="utils__fade-in-fast-top"
                // src={require('../images/stickers/oldschool-negra.png')}
                src={`${process.env.REACT_APP_CDN}/oldschool-negra.webp`}
                alt="Old School"
            />

            {
                socialImages.spotify ? (

                    <SpotifyLogo loading="lazy" src={socialImages.spotify} alt="flecha" className="utils__fade-in-fast-center" />

                ) : <></>
            }

            <Title className="utils__fade-in-top">¿YA ESCUCHASTE<br />NUESTRA<br />PLAYLIST?</Title>

            <SendButton
                className="utils__fade-in-fast-top"
                onClick={(e) => {

                    window.open("https://open.spotify.com/playlist/3OxDFm2V0Lq69Y2xcqFePq?si=aioOyl8uSBG9cZyOfJHNew&pi=u-JwBCX0G1RDKO", "_blank");

                    handleLogEvent('click_boton_spotify')
                }}
            >
                IR A LA PLAYLIST
                {
                    socialImages.arrow ? (

                        <Arrow loading="lazy" src={socialImages.arrow} alt="flecha" />

                    ) : <></>
                }
            </SendButton>

        </SpotifyContainer>

    )
}

export default Spotify;