import styled from "styled-components"
import { device } from "../styles/stylesConfig"
import WantMyTicketTableButtons from "./WantMyTicketTableButtons"
import { useDispatch, useSelector } from "react-redux"
import { finishLoading } from "../actions/ui"
import { TicketProps, globalTexts } from "../constants/TextConstants"
import InfoFest from "./InfoFest"

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15vh;

    @media ${device.lg} {
        margin-bottom: 5vh;
    }

`

const DesktopDiv = styled.div`
    max-width: 1700px;
    width: 95%;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10vh;

    @media ${device.lg} {
        display: none;
    }
`

const MobileDiv = styled.div`
    display: none;
    max-width: 1700px;
    width: 95%;
    position: relative;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10vh;

    @media ${device.lg} {
        display: flex;
        flex-direction: column;
        grid-template-columns: unset;
        max-width: 900px;
    }

    @media ${device.lg} {
        width: 90%;
    }

    
    @media ${device.md} {
        width: 80%;
    }

`

const Div1 = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    position: relative;

    @media ${device.lg} {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        justify-content: unset;
        flex-direction: unset;
        align-items: unset;
        gap: 10px;
    }

    @media ${device.md} {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: start;
    }
`

const Div2 = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media ${device.lg} {
        padding-top: 100px;
    }

    @media ${device.sm} {
        padding-top: 60px;
    }
`

const ActionsContainer = styled.div`
    position: relative;
    height: fit-content;
    z-index: 3;
    margin-top: 70px;
    margin-left: 5vw;

    @media ${device.lg} {
        position: relative;
        top: unset;
        left: unset;

        margin-top: unset;
        margin-left: unset;

        align-self: center;
    }

    @media ${device.md} {
        align-self: start;
    }

    @media ${device.sm} {

    }

`

const StickersContainer = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-self: center;

    @media ${device.lg} {
        margin-top: 0px;
        position: absolute;
        left: 100px;
        bottom: -30px;
    }

    @media ${device.md} {
        left: 0px;
        bottom: -270px;
        display: none;
    }

    @media ${device.sm} {
        left: -10px;
        bottom: -190px;
    }

    @media ${device.xs} {
        bottom: -160px;
    }
`

const Temperature = styled.img`
    width: 120px;
    height: auto;
    z-index: 1;
    transform: rotate(-15deg);
    margin-top: 100%;
    
    @media ${device.xl} {
        width: 100px;
    }

    @media ${device.lg} {
        margin-top: unset;
    }

    @media ${device.md} {
        width: 70px;
    }

    @media ${device.sm} {
        width: 50px;
    }

    @media ${device.xs} {
        width: 40px;
    }
`

const YellowFire = styled.img`
    width: 17vh;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -20vh;
    left: -15%;
    z-index: 0;

    @media (max-width: 1700px) {
        left: -5%;
    }

    @media ${device.lg} {
        display: none;
    }

`

const PasslineContainer = styled.div`
    width: 400px;
    height: 400px;
    z-index: 1;
    margin-top: 80px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: center;
    
    @media ${device.lg} {
        width: 350px;
        height: 350px;
    }

    @media ${device.sm} {
        margin-top: 75px;
        width: 300px;
        height: 300px;
    }

    @media ${device.xs} {
        margin-top: 70px;
        width: 200px;
        height: 200px;
    }
`

const Passline = styled.img`
    width: 100%;
    height: 100%;
    z-index: 1;

`

const PasslineTape = styled.img`
    width: 300px;
    height: auto;
    position: absolute;
    margin: auto;
    top: -130px;
    left: 0;
    right: 0;
    z-index: 1;
    transform: rotate(35deg);

    @media ${device.lg} {

    }
    
    @media ${device.md} {
        width: 250px;
        top: -100px;
    }

    @media ${device.xs} {
        width: 150px;
        top: -60px;
    }

`

const OldSchoolReggaeton = styled.img`
    width: 140px;
    height: auto;
    position: absolute;
    margin: auto;
    bottom: -60px;
    left: -60px;
    z-index: 1;

    @media ${device.sm} {
        width: 100px;
        bottom: -40px;
        left: -40px;
    }

    @media ${device.xs} {
        width: 70px;
        bottom: -25px;
        left: -25px;
    }
`

const Times = styled.div`
    width: fit-content;
    height: fit-content;
    position: relative;
    font-size: 20px;
    font-weight: bold;
    color: #111;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s ease;
    padding: 5px 40px;
    line-height: 34px;
    border-radius: 10px;
    z-index: 2;
    position: absolute;
    transform: rotate(-5deg);
    text-align: center;
    bottom: 0px;
    left: 5vw;
    right: 0px;
    margin: auto;

    @media ${device.lg} {
        margin: unset;
        font-size: 16px;
        padding: 5px 30px;
        line-height: 25px;
        top: -50px;
        left: -100px;
    }

    @media ${device.sm} {
        font-size: 12px;
        padding: 5px 20px;
        line-height: 20px;
        top: -30px;
        left: -30px;
    }

    @media ${device.xs} {
        font-size: 10px;
        padding: 5px 20px;
        line-height: 15px;
        top: -30px;
        left: -50px;
    }

`

const LogoYellowFire = styled.img`
    width: 90px;
    height: auto;
    position: absolute;
    top: -60px;
    left: -50px;

    @media ${device.md} {
        width: 70px;
        top: -50px;
        left: -35px;
    }


    @media ${device.sm} {
        width: 50px;
        top: -30px;
        left: -25px;
    }

    @media ${device.xs} {
        width: 40px;
        top: -25px;
        left: -20px;
    }
`

const F = styled.img`
    width: 150px;
    height: auto;
    object-fit: contain;
    z-index: 1;
    margin-top: 0px;
    margin-right: -100px;
    align-self: end;


    @media ${device.lg} {
        margin-top: unset;
        margin-left: unset;
        position: absolute;
        width: 120px;
        bottom: -100px;
        top: unset;
        right: 20px;
    }

    @media ${device.md} {
        width: 120px;
        bottom: -120px;
        right: 30px;
    }

    @media ${device.sm} {
        width: 100px;
        bottom: -100px;
        right: 30px;
    }

    @media ${device.xs} {
        width: 80px;
        bottom: -80px;
        right: 50px;
    }

`

interface Props {
    ticket: TicketProps;
}

export const IntroductionTickets = ({ ticket }: Props) => {

    const { loading } = useSelector((state: any) => state.ui);

    const dispatch = useDispatch()

    return (

        <Container>

            <DesktopDiv>

                <Div1>

                    <ActionsContainer className={!loading ? 'utils__fade-in-right' : ''}>

                        <WantMyTicketTableButtons ticket={ticket} />

                    </ActionsContainer>

                    <StickersContainer>

                        <Temperature
                            className={!loading ? 'utils__fade-in-center' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/termometro-blanco.png`)}
                            src={`${process.env.REACT_APP_CDN}/termometro-blanco.png`}
                            alt="Termometro"
                        />

                    </StickersContainer>

                    <YellowFire
                        // src={require(`../images/stickers/fuego-amarillo.png`)}
                        src={`${process.env.REACT_APP_CDN}/fuego-amarillo.png`}
                        alt="YellowFire"
                    />

                    <Times>

                        Apertura: 23:59hs<br />Cierre: 06:30hs
                        {

                            <LogoYellowFire
                                className={!loading ? 'utils__fade-in-right' : ''}
                                loading="lazy"
                                //  src={require('../images/introductionTickets/fiebre-amarillo.png')} 
                                src={`${process.env.REACT_APP_CDN}/fiebre-amarillo.png`}
                                alt="Fiebre amarillo"
                            />

                        }
                    </Times>


                </Div1>


                <Div2>

                    <PasslineContainer className={!loading ? 'utils__fade-in-center' : ''}>

                        <Passline
                            className={!loading ? 'utils__fade-in-left' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/passline.png`)}
                            src={`${process.env.REACT_APP_CDN}/${ticket.flyer}`}
                            alt="Passline"
                            onLoad={() => { dispatch(finishLoading()); }}
                        />

                        <PasslineTape
                            loading="eager"
                            // src={require(`../images/stickers/cinta-fina.png`)}
                            src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                            alt="Cinta"
                        />

                        <OldSchoolReggaeton
                            className={!loading ? 'utils__fade-in-right' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/old-shool-blanco.png`)}
                            src={`${process.env.REACT_APP_CDN}/old-shool-blanco.webp`}
                            alt="OldSchool"
                        />

                        <F
                            className={!loading ? 'utils__fade-in-left' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/efe.png`)}
                            src={`${process.env.REACT_APP_CDN}/efe.png`}
                            alt="F"
                        />

                    </PasslineContainer>

                </Div2>

            </DesktopDiv>

            <MobileDiv>

                <Div1>

                    <ActionsContainer className={!loading ? 'utils__fade-in-right' : ''}>

                        <WantMyTicketTableButtons ticket={ticket} />

                    </ActionsContainer>

                    <StickersContainer>

                        <Temperature
                            className={!loading ? 'utils__fade-in-right' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/termometro-blanco.png`)}
                            src={`${process.env.REACT_APP_CDN}/termometro-blanco.png`}
                            alt="Termometro"
                        />

                    </StickersContainer>

                    <PasslineContainer>

                        <Passline
                            className={!loading ? 'utils__fade-in-top' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/passline.png`)}
                            src={`${process.env.REACT_APP_CDN}/${ticket.flyer}`}
                            alt="Passline"
                            onLoad={() => { dispatch(finishLoading()); }}
                        />

                        <PasslineTape
                            loading="eager"
                            // src={require(`../images/stickers/cinta-fina.png`)}
                            src={`${process.env.REACT_APP_CDN}/cinta-fina.webp`}
                            alt="Cinta"
                        />

                        <OldSchoolReggaeton
                            className={!loading ? 'utils__fade-in-right' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/old-shool-blanco.png`)}
                            src={`${process.env.REACT_APP_CDN}/old-shool-blanco.webp`}
                            alt="OldSchool"
                        />

                        <Times className={!loading ? 'utils__fade-in-right' : ''}>

                            Apertura: 23:59hs<br />Cierre: 06:30hs
                            {

                                <LogoYellowFire
                                    className={!loading ? 'utils__fade-in-right' : ''}
                                    loading="lazy"
                                    //  src={require('../images/introductionTickets/fiebre-amarillo.png')} 
                                    src={`${process.env.REACT_APP_CDN}/fiebre-amarillo.png`}
                                    alt="Fiebre amarillo"
                                />

                            }
                        </Times>

                        <F
                            className={!loading ? 'utils__fade-in-left' : ''}
                            loading="eager"
                            // src={require(`../images/introductionTickets/efe.png`)}
                            src={`${process.env.REACT_APP_CDN}/efe.png`}
                            alt="F"
                        />

                    </PasslineContainer>

                </Div1>

            </MobileDiv>

            <InfoFest ticket={ticket} />

        </Container >

    )
}

export default IntroductionTickets;